import React, { Component } from 'react';
import { SingleDatePicker } from 'react-dates'
import moment from 'moment';
import { dateFormat, getCurrentDate } from '../../../shared/commonHelper';
import Select from "react-virtualized-select";
import { Field } from 'formik';
import Pagination from '../../shared/Pagination';
import { Formik } from 'formik';
import HotelCancellationComponent from './HotelCancellationComponent';
import { Alert } from 'reactstrap';

class HotelBookingSearchComponent extends Component {
    constructor(props) {
        super();
        let current_date = moment(getCurrentDate()).format('YYYY-MM-DD');
        this.state = {
            checkInDate: moment(),
            checkOutDate: moment(),
            checkInFocused: false,
            checkOutFocused: false,
            perPageLimit: 4,
            currentPage: 1,
            limitOffSet: '',
            cityArray: [],
            showHotelCancelPopup: false,
            searchValues:'&booking_status=all&booking_date_from='+current_date+'&booking_date_to='+current_date,
            currentDate :current_date,
            currentBookingId:"",
            releaseTripJackBookingStatus:false
        }
    }

    onCheckInFocusChange = ({ focused }) => {
        this.setState({ checkInFocused: focused })
    }

    onCheckOutFocusChange = ({ focused }) => {
        this.setState({ checkOutFocused: focused })
    }

    changeDateTimeFormat(value) {
        let finalDate = moment(value).format('YYYY-MM-DD');
        return finalDate;
    }

    changeDateFormat(value) {
        let finalDate = moment(value).format('YYYY-MM-DD');
        return finalDate;
    }

    setDestinationCityName = (selectedDestinationCity, setFieldValue) => {
        if (selectedDestinationCity) {
            setFieldValue('destinationCityFullName', selectedDestinationCity.label);
            setFieldValue('destinationCityName', selectedDestinationCity.cityName);
            setFieldValue('destination', selectedDestinationCity.value);
            setFieldValue('city_id', selectedDestinationCity.newValue);
        } else {
            setFieldValue('destinationCityFullName', '');
            setFieldValue('destinationCityName', '');
            setFieldValue('destination', '');
            setFieldValue('city_id', '');
        }

        this.setState({ selectedDestinationCity });
    }

    enterPressed = (handleSubmit, event) => { //event is not passed from methd calling as its received by default when it was binded to our context
        var code = event.keyCode || event.which;
        if (code === 13) {
            handleSubmit();
        }
    }

    //function called on click of "invoice" button to print that booking invoice
    printInvoice = (booking_id) => {
        let printInvoiceData = {
            "booking_id": booking_id
        }
        localStorage.setItem("print-hotel-invoice", JSON.stringify(printInvoiceData));
        window.open('/print-hotel-invoice', "_blank");
    }

    //function called on click of "voucher" button to print that booking voucher
    printVoucher = (booking_id) => {
        let printInvoiceData = {
            "booking_id": booking_id
        }
        localStorage.setItem("print-hotel-voucher-data", JSON.stringify(printInvoiceData));
        window.open('/hotel-voucher', "_blank");
    }

    //function called on click of "open" button to get that booking's details
    openBookingDetails = (transaction_no) => {
        let hotelBookingData = {
            "booking_id": transaction_no,
        };

        localStorage.setItem("hotel-booking-details", JSON.stringify(hotelBookingData));
        window.open('/hotel-booking', "_blank");
    }

    printHotelCreditNote = (data) => {
        let printHotelCreditNote = {
            "booking_id": parseInt(data.booking_id)
        }
        localStorage.setItem("print-hotel-credit-note", JSON.stringify(printHotelCreditNote));
        window.open('/print-hotel-credit-note', "_blank");
    }
    getHotelCitiesByFilter(filterString) {
        if (filterString && filterString.length > 1) {
            const successCb = () => {
                let cityArray = [];
                if (this.props.hotelCitiesResponse.response) {
                    cityArray = this.props.hotelCitiesResponse.response.map(function (cityData, index) {
                        return ({
                            value: cityData.destination,
                            newValue: cityData.city_id, //using for searching
                            label: cityData.destination + '(' + cityData.country + ')',
                            cityName: cityData.destination,
                            countryName: cityData.country,
                            countryCode: cityData.country_code,
                        });
                    })
                }
                this.setState({ cityArray: cityArray });
            }
            this.props.getHotelCitiesAction(filterString, successCb);
        }
    }

    handleConfirmationPopup = (event, id) => {
        var element;
        if (event === true) {
            element = document.getElementById(id);
            element.classList.add('show');
        }
        else {
            element = document.getElementById(id);
            element.classList.remove('show');
        }
    }
    
    //get hotel bookings list & status and cities
    componentDidMount() {
        this.props.getBookingStatus(this.props.location.state.clickedProductCode);
        this.getHotelCitiesByFilter('default');
        let current_date = moment(getCurrentDate()).format('YYYY-MM-DD');
        let limitOffset = "?limit=" + this.state.perPageLimit + "&offset=0&booking_status=all&booking_date_from=" + current_date + "&booking_date_to=" + current_date;
        this.setState({
            limitOffSet: limitOffset
        });
        this.props.getMyHotelBookings(limitOffset);
    }

    //function to create query string to pass with API call to get the results as per search panel values or clicked pagination
    createSearchingQueryString = (values, activePage = 0) => {
        var offset;
        if (activePage === 0) {
            offset = 0 * this.state.perPageLimit;
        }
        else {
            offset = (activePage - 1) * this.state.perPageLimit;
        }

        var limitOffset = "?offset=" + offset + "&limit=" + this.state.perPageLimit;
        var searchValues = '&booking_status=all';
        // let current_date = moment(getCurrentDate()).format('YYYY-MM-DD');
        //If booking status search is filled then value else all
        // limitOffset = values.booking_status !== '' ? limitOffset + '&booking_status=' + values.booking_status : limitOffset + '&booking_status=all&booking_date_from=' + current_date;
        limitOffset = values.booking_status !== '' ? limitOffset + '&booking_status=' + values.booking_status : limitOffset + '&booking_status=all';
        if (values.check_in_date && values.check_in_date !== '') {
           // limitOffset = limitOffset + '&booking_date_from=' + values.check_in_date;
           searchValues = '&booking_date_from='+values.check_in_date;
        }
        if (values.check_out_date !== '' && values.check_out_date !== '') {
           // limitOffset = limitOffset + '&booking_date_to=' + values.check_out_date;
            searchValues = '&booking_date_to='+values.check_out_date;
        }
        if (values.hotel_name !== '') {
            limitOffset = limitOffset + '&hotel_name=' + values.hotel_name;
            searchValues = '&hotel_name='+values.hotel_name;
        }
        if (values.booking_transaction_ref !== '') {
            limitOffset = limitOffset + '&booking_transaction_ref=' + values.booking_transaction_ref;
            searchValues = '&booking_transaction_ref='+values.booking_transaction_ref;
        }
        if (values.guest_name !== '') {
            limitOffset = limitOffset + '&guest_name=' + values.guest_name;
            searchValues = '&guest_name=' +values.guest_name;
        }
        if (values.confirmation_no !== '') {
            limitOffset = limitOffset + '&confirmation_no=' + values.confirmation_no;
            searchValues = '&confirmation_no=' +values.confirmation_no;
        }
        if (values.destinationCityName && values.destinationCityName !== '') {
            limitOffset = limitOffset + '&destination=' + values.city_id;
        }
        if(values.hotel_name === '' && values.booking_transaction_ref === '' && values.guest_name === '' && values.confirmation_no === '') {
            limitOffset = limitOffset + '&booking_date_from=' + values.check_in_date+ '&booking_date_to=' + values.check_out_date;
        }

        this.setState({
            limitOffSet: limitOffset,
            searchValues: searchValues
        });

        this.props.getMyHotelBookings(limitOffset);
    }

    resetFormValues = (setFieldValue) => {
        this.setState({
            checkInDate: moment(),
            checkOutDate: moment(),
            selectedDestinationCity: null
        })
        setFieldValue("booking_status", 'all');
        setFieldValue("booking_transaction_ref", '');
        setFieldValue("guest_name", '');
        setFieldValue("check_in_date", null);
        setFieldValue("check_out_date", '');
        setFieldValue("hotel_name", '');
        setFieldValue("destinationCityName", '');
        setFieldValue("confirmation_no", '');

        let current_date = moment(getCurrentDate()).format('YYYY-MM-DD');
        var limitOffset = "?offset=0&limit=4";
        limitOffset = limitOffset + '&booking_status=all&booking_date_from=' + current_date + '&booking_date_to=' + current_date;
        this.props.getMyHotelBookings(limitOffset);
    }

    //function to render search panel
    renderSearchForm = (formikProps, props) => {
        const { bookingStatusDetails } = props;
        const { values, setFieldValue, handleBlur, handleSubmit, handleChange } = formikProps;
        const { checkInDate, checkOutDate } = this.state;
        let diffTime = Math.abs(new Date(checkInDate) - new Date(checkOutDate));
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        const current_date = moment().format('YYYY-MM-DD');
        return (
            <form>
                {
                    diffDays > 31 ? <Alert color="danger">You can search booking of maximum 31 days.</Alert> : ''
                }
                <div className="form-row">
                    <div className="col-sm-12 col-lg-12">
                        <div className="form-group">
                            <label className="control-label">BOOKING STATUS:</label>
                            <div className="custom-select-box">
                                <select className="form-control custom-filed" name="booking_status" value={values.booking_status} onBlur={handleBlur} onChange={handleChange} onKeyPress={this.enterPressed.bind(this, handleSubmit)}>
                                    <option value="all">All</option>
                                    {
                                        bookingStatusDetails.response && bookingStatusDetails.response.booking_status.map((item, key) => {
                                            return (
                                                <option key={key} value={item.status}>{item.status_name}</option>
                                            );
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-12">
                        <div className="form-group">
                            <label className="control-label">Booking Start Date :</label>
                            <div className="input-group input-addon single-calendar">
                                {
                                    <SingleDatePicker
                                        date={checkInDate}
                                        onDateChange={checkInDate => {
                                            this.setState({ checkInDate });
                                            setFieldValue("check_in_date", this.changeDateTimeFormat(checkInDate))
                                        }}
                                        focused={this.state.checkInFocused}
                                        onFocusChange={this.onCheckInFocusChange}
                                        id="check_in_date"
                                        displayFormat="DD-MM-YYYY"
                                        readOnly={true}
                                        showDefaultInputIcon={true}
                                        inputIconPosition={"after"}
                                        isOutsideRange={() => false}
                                        hideKeyboardShortcutsPanel={true}
                                        isDayHighlighted={day => day.isSame(current_date, 'day')}
                                        reopenPickerOnClearDate={true}
                                        withPortal={true}

                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-12">
                        <div className="form-group">
                            <label className="control-label">Booking End Date :</label>
                            <div className="input-group input-addon single-calendar">
                                {
                                    <SingleDatePicker
                                        date={checkOutDate}
                                        onDateChange={checkOutDate => {
                                            this.setState({ checkOutDate });
                                            setFieldValue("check_out_date", this.changeDateFormat(checkOutDate))
                                        }}
                                        focused={this.state.checkOutFocused}
                                        onFocusChange={this.onCheckOutFocusChange}
                                        id="check_out_date"
                                        displayFormat="DD-MM-YYYY"
                                        readOnly={true}
                                        showDefaultInputIcon={true}
                                        inputIconPosition={"after"}
                                        isOutsideRange={() => false}
                                        hideKeyboardShortcutsPanel={true}
                                        isDayHighlighted={day => day.isSame(current_date, 'day')}
                                        reopenPickerOnClearDate={true}
                                        withPortal={true}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-12">
                        <div className="form-group">
                            <label className="control-label">Reference NO:</label>
                            <Field className="form-control custom-filed" type="text"  name="booking_transaction_ref" onKeyPress={this.enterPressed.bind(this, handleSubmit)} onKeyUp={() => {this.setState({
                                                                     checkInDate: null,
                                                                     checkOutDate: null
                                                                })}} />
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-12">
                        <div className="form-group">
                            <label className="control-label">Confirmation NO:</label>
                            <Field className="form-control custom-filed" type="text"  name="confirmation_no" onKeyPress={this.enterPressed.bind(this, handleSubmit)} onKeyUp={() => {this.setState({
                                                                    checkInDate: null,
                                                                    checkOutDate: null
                                                                })}}/>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-12">
                        <div className="form-group">
                            <label className="control-label">Hotel Name</label>
                            <Field className="form-control custom-filed" type="text"  name="hotel_name" onKeyPress={this.enterPressed.bind(this, handleSubmit)} onKeyUp={() => {this.setState({
                                                                    checkInDate: null,
                                                                    checkOutDate: null
                                                                })}} />
                        </div>
                    </div>
                    
                    <div className="col-sm-12 col-lg-12">
                        <div className="form-group">
                            <label className="control-label">Guest NAME:</label>
                            <Field className="form-control custom-filed" type="text"  name="guest_name" onKeyPress={this.enterPressed.bind(this, handleSubmit)} onKeyUp={() => {this.setState({
                                                                     checkInDate: null,
                                                                     checkOutDate: null
                                                                })}}/>
                        </div>
                    </div>
                    
                    <div className="col-sm-12 col-lg-12">
                        <div className="form-group">
                            <label className="control-label">Destination:</label>
                            <div className="custom-select-box">
                                <Select
                                    component={Select}
                                    value={this.state.selectedDestinationCity}
                                    options={this.state.cityArray}
                                    name="destination"
                                    className="custom-select-box"
                                    onBlur={this.destinationHandleBlur}
                                    onChange={(event) => { this.setDestinationCityName(event, setFieldValue) }}
                                    onInputChange={(event) => { this.getHotelCitiesByFilter(event) }}
                                    placeholder="Select Destination City"
                                    openMenuOnFocus="true"
                                    tabIndex="0"
                                >
                                </Select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="btn-block text-center">
                    {diffDays && diffDays > 31 ?
                        <button type="button" className="btn btn-sky-blue" onClick={handleSubmit} disabled>Search</button>
                        :
                        <button type="button" className="btn btn-sky-blue" onClick={handleSubmit}>Search</button>
                    }
                    <button type="button" className="btn btn-grey" onClick={() => this.resetFormValues(setFieldValue)}>Cancel</button>
                </div>
            </form>
        );
    }

    //function to get records as per clicked page from pagination
    getHotelBookingRecordsPerPage(activePage, formValues) {
        this.setState({ currentPage: activePage });
        this.createSearchingQueryString(formValues, activePage);
    }

    renderHotelBookingsListing = (formikProps, props) => {
        const { myHotelBookingDetails } = props;
        return (
            <div className="profile-detail-info">
                <div className="edit-profile-bar">
                    <div className="pull-right">
                        { /* eslint-disable-next-line */}
                        <a href="/" onClick={(e)=>e.preventDefault()} className="btn btn-sky-blue profile-btn"><i className="icon icon-menu"></i>Profile Menus</a>
                    </div>
                </div>
                <div className="tabs-container-detail search-transation">
                    <div className="statement-info">
                        <div className="tabs-top">
                            <div className="tabs-title">
                                <h2><i className="fa fa-bed"></i><span>SEARCH YOUR BOOKING</span></h2>
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: this.props.location.state.pageDescription }} />
                        </div>
                        <div className="statement-outer statement-transaction">
                            <div className="form-row">
                                <div className="col-left">
                                    <div className="transtion-table-outer">
                                        <div className="table-transaction">
                                            {/* booked hotels listing section ================================================= Start */}
                                            <ul className="transaction-list">
                                                {
                                                    (myHotelBookingDetails.loading === false)
                                                        ?
                                                        myHotelBookingDetails && myHotelBookingDetails.response && myHotelBookingDetails.response.booking_list.length > 0
                                                            ?
                                                            myHotelBookingDetails.response.booking_list.map((item, index) => {
                                                                let leadPax = [];
                                                                /* eslint-disable-next-line */
                                                                item.pax_names.map((paxNames, index) => {
                                                                    if (paxNames.is_lead_pax === 1) {
                                                                        leadPax.push(paxNames.name);
                                                                    }
                                                                });
                                                                return (
                                                                    <li key={index}>
                                                                        <div className="col-transaction">
                                                                            <div className="col-table col-thead">
                                                                                <div className="cols col-name">
                                                                                    <span></span>
                                                                                </div>
                                                                                <div className="cols col-hold">
                                                                                    <span className="hold-tk">{item.booking_status_name && item.booking_status_name.toUpperCase()}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-table col-tbody">
                                                                                <div className="cols col-name">
                                                                                    {
                                                                                        leadPax.map((data, newKey) => {
                                                                                            return (
                                                                                                <span key={newKey}>{data}</span>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                                <div className="cols">
                                                                                    <div className="ticket-info">
                                                                                        <span className="text-bold">{item.hotel_name + ', ' + item.city}</span>
                                                                                    </div>
                                                                                    <div className="col-bottom">
                                                                                        <div className="book-datail">
                                                                                            <span>Check In:</span>
                                                                                            <small>{dateFormat(item.check_in_date, 'FDAY, DD MMM YYYY')}</small>
                                                                                            <span>Check Out:</span>
                                                                                            <small>{dateFormat(item.check_out_date, 'FDAY, DD MMM YYYY')}</small>
                                                                                            <span>Booked On:</span>
                                                                                            <small>{dateFormat(item.booking_date, 'FDAY, DD MMM YYYY')}</small>
                                                                                        </div>
                                                                                        {/* <div className="book-datail"><span>Last Ticketing Date:</span> <small>{dateFormat(item.segments[0][0].departure_time, 'FDAY, DD MMM YYYY')}</small></div> */}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="cols col-pnr">
                                                                                    <div className="inner-pnr">
                                                                                        <div className="col-info"><span className="text-bold">Confirmation No:</span><small>{item.confirmation_no}</small></div>
                                                                                        <div className="col-info"><span className="text-bold">Reference No:</span> <small>{item.reference_number}</small></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="foot-block">
                                                                            {
                                                                            this.props.statusCheckDataTripjackData.response && this.props.statusCheckDataTripjackData.response.booking_status === 18  && this.props.statusCheckDataTripjackData.response.tripjack_bookingId !== "" && item.supplier_type==="tripjack" &&
                                                                            <span className="release-error" style={{color: '#f4a934', display: this.props.checkPnrStatus && this.props.checkPnrStatus === 'true' && this.props.stausHotelTripJackBooking === item.booking_id ? 'block' : 'none'}}>
                                                                                Status is Pending. Please try again later.
                                                                            </span>
                                                                        }
                                                                        {
                                                                            this.props.statusCheckDataTripjackData.response && (this.props.statusCheckDataTripjackData.response.booking_status === 16 || this.props.statusCheckDataTripjackData.response.booking_status === 12) && this.props.statusCheckDataTripjackData.response.tripjack_bookingId !== "" && item.supplier_type==="tripjack" &&
                                                                            <span className="release-error" style={{color: 'green', display: this.props.checkPnrStatus && this.props.checkPnrStatus === 'true' && this.props.stausHotelTripJackBooking === item.booking_id ? 'block' : 'none'}}>
                                                                              {this.props.statusCheckDataTripjackData.response.booking_status === 12 ? "Booking is successfully hold." : "Booking is successfully created."}</span>
                                                                        }
                                                                        {
                                                                            this.props.releaseHotelTripJackDetail && this.props.releaseHotelTripJackDetail.error && this.props.releaseHotelTripJackDetail.error.message && item.supplier_type==="tripjack" &&
                                                                            <span className="release-error" style={{color: 'red', display: this.state.currentBookingId === item.booking_id ? 'block' : 'none'}}>
                                                                                {this.props.releaseHotelTripJackDetail.error.message}</span>
                                                                        }
                                                                        {
                                                                            this.state.releaseTripJackBookingStatus &&  item.supplier_type==="tripjack" &&
                                                                            <span className="release-error" style={{color: 'green', display: this.state.currentBookingId === item.booking_id ? 'block' : 'none'}}>
                                                                                Booking released successfully</span>
                                                                        }
                                                                            {
                                                                                
                                                                                <React.Fragment>
                                                                                    {
                                                                                        (item.booking_status_id === 16 || item.booking_status_id === 15 || item.booking_status_id === 14 || item.booking_status_id === 13) &&
                                                                                        <React.Fragment>
                                                                                            <button className="btn btn-yellow" onClick={() => this.printVoucher(item.booking_id)}><i className="fa fa-print" aria-hidden="true"></i> Voucher</button>
                                                                                            <button className="btn btn-yellow" onClick={() => this.printInvoice(item.booking_id)}><i className="fa fa-print" aria-hidden="true"></i> Invoice</button>
                                                                                        </React.Fragment>
                                                                                    }
                                                                                    {
                                                                                      item.booking_status_id === 18 && item.supplier_type === 'tripjack'  ? 
                                                                                         /* eslint-disable-next-line */
                                                                                        <a href="/" className={`btn btn-grey loading-btn ${this.props.checkPnrStatus && this.props.checkPnrStatus === 'true'
                                                                                               && this.props.stausHotelTripJackBooking === item.booking_id && 'btn-disabled'}`}
                                                                                               onClick={(e) => {e.preventDefault(); this.handleConfirmationPopup(true, 'show-popup_' + index)}}><span>{
                                                                                                   this.props.checkPnrStatus && this.props.checkPnrStatus === 'true' && this.props.stausHotelTripJackBooking=== item.booking_id ?
                                                                                                       'Status Checked'
                                                                                                       :
                                                                                                       'Check Status'
                                                                                               }</span>
                                                                                               {
                                                                                                   this.props.statusCheckDataTripjackData.loading && this.props.stausHotelTripJackBooking === item.booking_id
                                                                                                       ?
                                                                                                       <div className="flight-animated"><label><span></span>
                                                                                                            <img src="http://jfdev.testappsnow.com/assets/images/search_hotel.gif" alt="" /></label>
                                                                                                        </div>
                                                                                                       : ''
                                                                                               }
                                                                                           </a> : ""
                                                                                   }
                                                                                </React.Fragment>
                                                                            }
                                                                            {
                                                                                ((item.booking_status_id === 16 || item.booking_status_id === 15) && (item.booking_status_id !== 12)) &&
                                                                                <button className="btn btn-red" onClick={() => this.showHotelCancelTicket(item, true)}>Cancel</button>
                                                                            }
                                                                            {
                                                                                item.booking_status_id === 12 &&
                                                                                <button className="btn btn-red" onClick={() => this.handleConfirmationPopup(true, 'show-popup_' + index)}>Release {this.props.releaseHotelTripJackDetail.loading && this.state.currentBookingId === item.booking_id ? <div className="flight-animated"><label><span></span>
                                                                                    <img src="http://jfdev.testappsnow.com/assets/images/search_hotel.gif" alt="" /></label>
                                                                                </div> : ""}</button>
                                                                            }
                                                                            {

                                                                                item.booking_status && item.booking_status.trim() === 'cancelled' &&
                                                                                <button className="btn btn-yellow" onClick={() => this.printHotelCreditNote(item)}><i className="fa fa-print" aria-hidden="true"></i> Credit Note</button>
                                                                            }
                                                                            <button className="btn btn-green" onClick={() => this.openBookingDetails(item.booking_id)}>Open</button>
                                                                            <div className={`popup-crad confirmation-popup`} id={'show-popup_' + index}>
                                                                                    <div className="popup-outer">
                                                                                        <div className="popup-content">
                                                                                            <div className="popup-head">
                                                                                                <h2>Release Confirmation</h2>
                                                                                                <div className="popup-close"><span className="icon icon-cross" onClick={() => this.handleConfirmationPopup(false, 'show-popup_' + index)} /></div>
                                                                                            </div>
                                                                                            <div className="popup-body">
                                                                                                <div className="inner-confirmation">
                                                                                                    <p>Are you sure you want to continue?</p>
                                                                                                    <div className="popup-footer">
                                                                                                        <button className="btn btn-orange" onClick={() => this.callHotelTripjackCheckStatus(item, 'show-popup_' + index)}>Yes</button>
                                                                                                        <button className="btn btn-grey" onClick={() => this.handleConfirmationPopup(false, 'show-popup_' + index)}>No</button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="popup-overlay" />
                                                                                </div>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            })
                                                            :
                                                            <div className="no-result-wrap">
                                                                <div className="result-wrap-outer">
                                                                    <div className="block">
                                                                        <div className="no-result-box">
                                                                            <h2>No results found</h2>
                                                                            <p>We can’t find any item matching your search.</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        :
                                                        <div className="loader relative-loader" style={{ "display": "block" }}>
                                                            <div className="loader-content">
                                                                <div className="loader-ring blue-ring">
                                                                    <span></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                }
                                            </ul>
                                            {/* booked hotels listing section =================================================== End */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-right">
                                    <div className="search-transaction-form form-sec-block">
                                        <h3 className="title-search">Filter</h3>
                                        <div className="search-form">
                                            {/* search panel (filters) section */}
                                            {this.renderSearchForm(formikProps, props)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* pagination section for booked hotels listing ================================================= Start */}
                <div className="table-footer">
                    <Pagination
                        totalRecord={myHotelBookingDetails.response && myHotelBookingDetails.response.total_count}
                        pageLimit={this.state.perPageLimit}
                        activePage={this.state.currentPage}
                        getRecordsPerPage={(value) => this.getHotelBookingRecordsPerPage(value, formikProps.values)}
                    />
                </div>
                {/* pagination section for booked hotels listing =================================================== End */}
            </div>
        );
    }

    showHotelCancelTicket = (item, popupValue) => {
        this.setState({
            showHotelCancelPopup: popupValue,
            cancelHotelPopupValue: item
        })
    }
    callHotelTripjackCheckStatus = (item, id) => {
        const tripjackFormValues = {
            "tripjack_bookingId": item.tripjack_booking_id,
            "booking_id": item.booking_id,
        }
        var element = document.getElementById(id);
        element.classList.remove('show');
        if (item.booking_status_id === 12) {
            this.setState({ currentBookingId: item.booking_id })
            let errorCb = (response) => {
            };
            let successCb = () => {
                this.setState({releaseTripJackBookingStatus:true})
                let current_date = moment(getCurrentDate()).format('YYYY-MM-DD');
                let limitOffset = "?limit=" + this.state.perPageLimit + "&offset=0&booking_status=all&booking_date_from=" + current_date + "&booking_date_to=" + current_date;
                this.setState({
                    limitOffSet: limitOffset
                });
                setTimeout(() => {
                    let current_date = moment(getCurrentDate()).format('YYYY-MM-DD');
                    let limitOffset = "?limit=" + this.state.perPageLimit + "&offset=0&booking_status=all&booking_date_from=" + current_date + "&booking_date_to=" + current_date;
                    this.setState({
                        limitOffSet: limitOffset
                    });
                    this.props.getMyHotelBookings(limitOffset);
                }, 3000);
            }
            this.props.releaseHotelTripJack(tripjackFormValues, successCb, errorCb)
        } else {
            this.props.chekStatusHotelTripJackApi(item, this.state.limitOffSet)
        }
    }

    render() {
        const initialValues = {
            booking_status: 'all',
            booking_transaction_ref: '',
            guest_name: '',
            check_in_date: this.state.currentDate,
            check_out_date: this.state.currentDate,
            hotel_name: '',
            destination: '',
            confirmation_no: ''
        };

        return (
            //putting complete component in formik because we need formikProps (like check in date, check out date, hotel name etc) in searching and pagination both
            <React.Fragment>
                <Formik
                    initialValues={initialValues} //Set initial values of search panel fields to blank
                    onSubmit={(values) => {
                        this.createSearchingQueryString(values);
                    }}
                    render={formikProps => this.renderHotelBookingsListing(formikProps, this.props)}
                />
                {
                    this.state.showHotelCancelPopup &&
                    <HotelCancellationComponent {...this.props} showHotelCancelTicket={this.showHotelCancelTicket} cancelHotelPopupValue={this.state.cancelHotelPopupValue} limitOffSet={this.state.limitOffSet}/>
                }
            </React.Fragment>
        )
    }
}

export default (HotelBookingSearchComponent);